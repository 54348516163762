/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { type SunshineChannel } from 'embercom/objects/standalone/zendesk';

export default class SunshineChannelModel extends Model implements SunshineChannel {
  @attr('boolean') declare canUserCreateMoreConversations: boolean | undefined;
  @attr declare actionColor: string;
  @attr declare attachmentsEnabled: boolean;
  @attr declare brandColor: string;
  @attr declare brandId: string;
  @attr declare conversationColor: string;
  @attr declare defaultResponder: SunshineChannel['defaultResponder'];
  @attr declare displayName: string;
  @attr declare displayStyle: string;
  @attr declare productLine: string;
  @attr declare status: string;
  @attr declare type: string;
}
