/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr, belongsTo } from '@ember-data/model';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';

import { post } from 'embercom/lib/ajax';

import type SunshineConfiguration from 'embercom/models/standalone/sunshine-configuration';
import type TicketsIntegration from 'embercom/models/standalone/tickets-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { IntegrationState } from 'embercom/objects/standalone/constants';

export default class ZendeskConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('string') declare subdomain: string;
  @attr('string') declare username: string;
  @attr('string') declare token: string;
  @attr('number', { defaultValue: IntegrationState.NotConfigured })
  declare ticketState: IntegrationState;

  @belongsTo('standalone/sunshine-configuration', { async: false })
  declare sunshineIntegration: SunshineConfiguration;

  @belongsTo('standalone/tickets-configuration', { async: false })
  declare ticketsIntegration: TicketsIntegration;

  get canUpdateAPISettings() {
    return this.subdomain && this.username && this.token;
  }

  get isConnected() {
    return !this.isPendingSetupForTickets;
  }

  get canUpdateSunshineSettings() {
    return (
      this.sunshineIntegration &&
      this.sunshineIntegration.sunshineAppId &&
      this.sunshineIntegration.apiKeyId &&
      this.sunshineIntegration.apiSecret &&
      !this.isPendingSetupForTickets &&
      !this.isFinEnabledForIntercomMessenger
    );
  }

  get isEnabledForTickets() {
    return this.ticketState === IntegrationState.Enabled;
  }

  get isDisabledForTickets() {
    return (
      this.ticketState === IntegrationState.Paused ||
      this.ticketState === IntegrationState.Created ||
      this.ticketState === IntegrationState.NotConfigured
    );
  }

  get isPendingSetupForTickets() {
    return this.ticketState === IntegrationState.NotConfigured;
  }

  get canRevokeTicketsApi() {
    return (
      !this.isEnabledForTickets &&
      !this.isEnabledForSunshine &&
      !this.isFinEnabledForIntercomMessenger
    );
  }

  get canRevokeSunshineApi() {
    return !this.isEnabledForSunshine;
  }

  get isFinEnabledForIntercomMessenger() {
    return this.finStandaloneService.intercomMessengerConfig.isMessengerEnabled;
  }

  get isEnabledForSunshine() {
    return (
      this.sunshineIntegration?.isEnabledForSunshineMessenger ||
      this.sunshineIntegration?.isEnabledForIntercomMessenger
    );
  }

  get adminBaseUrl(): string | undefined {
    if (!this.subdomain) {
      return undefined;
    }

    return `https://${this.subdomain}.zendesk.com/admin`;
  }

  async revoke() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/revoke`);
    this.token = '';
    await this.reload();
    taskFor(this.finStandaloneService.zendeskDataLoader.loadZendeskTicketsSetupData).perform();
  }

  async revokeSunshine() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/revoke_sunshine`);
    this.sunshineIntegration.unloadRecord();
    await this.reload();
  }

  async setFinUser(finUserId: number) {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/set_fin_user_for_tickets`, {
      fin_zendesk_user_id: finUserId,
    });

    this.ticketsIntegration.finZendeskUserId = finUserId;

    taskFor(this.finStandaloneService.zendeskDataLoader.loadZendeskTicketsSetupData).perform();

    await this.reload();
  }

  async activateForTickets() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/activate_for_tickets`);
    await this.reload();
  }

  async deactivateForTickets() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/deactivate_for_tickets`);
    await this.reload();
  }

  async activateForMessenger() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/activate_for_messenger`);
    await this.reload();
  }

  async deactivateForMessenger() {
    await post(`/ember/standalone/zendesk_configurations/${this.id}/deactivate_for_messenger`);
    await this.reload();
  }
}
