/* RESPONSIBLE TEAM: team-frontend-tech */
import SharedTooltip from '@intercom/intersection-shared-models/models/tooltip';
import calculateAppColors from '@intercom/intercom-messenger-colors';
import { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

export {
  AnchorTypes,
  TriggerTypes,
  TextSelectorTypes,
} from '@intercom/intersection-shared-models/models/tooltip';

const ANCHOR_MIN_CONTRAST = 1.6;
export default class Tooltip extends SharedTooltip {
  @attr('boolean') hasLinks;
  @service intl;

  get textColor() {
    let calcuatedColors = calculateAppColors({ primaryColor: this.anchorColor });

    if (calcuatedColors.primary_on_white_contrast > ANCHOR_MIN_CONTRAST) {
      return '#FFFFFF';
    } else {
      return '#000000';
    }
  }

  predicateDescription(comparison, value = '') {
    switch (comparison) {
      case 'eq': {
        return `${this.intl.t('outbound.tooltips.predicates.eq')} ${value}`;
      }
      case 'ne': {
        return `${this.intl.t('outbound.tooltips.predicates.ne')} ${value}`;
      }
      case 'contains': {
        return `${this.intl.t('outbound.tooltips.predicates.contains')} ${value}`;
      }
      case 'not_contains': {
        return `${this.intl.t('outbound.tooltips.predicates.not_contains')} ${value}`;
      }
      case 'starts_with': {
        return `${this.intl.t('outbound.tooltips.predicates.starts_with')} ${value}`;
      }
      case 'ends_with': {
        return `${this.intl.t('outbound.tooltips.predicates.ends_with')} ${value}`;
      }
      case 'known': {
        return `${this.intl.t('outbound.tooltips.predicates.known')}`;
      }
    }
  }

  get urlPredicatePreview() {
    return this.urlPredicateGroup.predicates
      .map((predicate, index) => {
        let childPredicatesValue = this.innerPredicates(predicate);
        return index > 0 ? `${predicate.type} ${childPredicatesValue}` : childPredicatesValue;
      })
      .join(' ');
  }

  innerPredicates(predicate) {
    return predicate.predicates
      ?.map((childPredicate, index) => {
        if (childPredicate.predicates !== undefined) {
          return index > 0
            ? `${predicate.type} ${this.innerPredicates(childPredicate)}`
            : this.innerPredicates(childPredicate);
        }
        return index > 0
          ? `${predicate.type} ${this.predicateDescription(
              childPredicate.comparison,
              childPredicate.value,
            )}`
          : `${this.predicateDescription(childPredicate.comparison, childPredicate.value)}`;
      })
      .join(' ');
  }
}
