/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { post, get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { IntegrationState } from 'embercom/objects/standalone/constants';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type SunshineMultipleConversation from 'embercom/models/standalone/sunshine-multiple-conversation';
import { use } from 'ember-resources/util/function-resource';
import { AsyncData } from 'embercom/resources/utils/async-data';

type Integration = {
  brand_id?: string;
  channel_integration_id: string;
};

export default class SunshineConfiguration extends Model {
  @service declare appService: { app: { id: string; currentAdmin: { id: string } } };
  @service declare finStandaloneService: FinStandaloneService;

  // Generic Sunshine configuration
  @attr('string') declare sunshineAppId: string;
  @attr('string') declare apiKeyId: string;
  @attr('string') declare apiSecret: string;
  @attr('boolean') declare multipleConversationsEnabled: boolean | undefined;
  @attr('number', { defaultValue: IntegrationState.NotConfigured })
  declare sunshineState: IntegrationState;

  // Intercom Messenger for Sunshine configuration
  @attr('number', { defaultValue: IntegrationState.NotConfigured })
  declare intercomMessengerState: IntegrationState;

  // Sunshine Messaging configuration
  @attr('number', { defaultValue: IntegrationState.NotConfigured })
  declare sunshineMessengerState: IntegrationState;
  @attr declare supportedIntegrations: Integration[];
  @attr('number') declare workflowRulesetId: number;

  @use ruleset = AsyncData<$TSFixMe>(async () => {
    let ruleset = await this.store.peekRecord('matching-system/ruleset', this.workflowRulesetId);
    if (!ruleset) {
      ruleset = await this.store.findRecord('matching-system/ruleset', this.workflowRulesetId);
    }

    let customBot = ruleset.rulesetLinks.firstObject.object;
    customBot.visualBuilderObject.editorConfig = customBot.config.editorConfig;
    return ruleset;
  });

  get isEnabledForIntercomMessenger() {
    return this.intercomMessengerState === IntegrationState.Enabled;
  }

  get isEnabledForSunshineMessenger() {
    return this.sunshineMessengerState === IntegrationState.Enabled;
  }

  get isSunshineConnected() {
    return this.sunshineState === IntegrationState.Created;
  }

  get isSunshineDisconnected() {
    return this.sunshineState === IntegrationState.NotConfigured;
  }

  // The ZendeskMultipleConversations component may load the sunshine-multiple-conversation model which, if needs be,
  // will figure out if they are enabled by attempting to create multiple Sunshine conversations for a test user.
  // Otherwise though, we can just check if the sunshineChannels have any channel that allows multiple conversations, or
  // otherwise rely on the multipleConversationsEnabled attribute from the DB model.
  get isMultiConversationsEnabled() {
    let sunshineMultipleConversations = this.store.peekRecord(
      'standalone/sunshine-multiple-conversation',
      this.appService.app.id,
    ) as SunshineMultipleConversation | undefined;
    if (sunshineMultipleConversations) {
      return sunshineMultipleConversations.enabled;
    }

    let anyChannelAllowsMultipleConversations = this.finStandaloneService.sunshineChannels.any(
      (channel) => channel.canUserCreateMoreConversations || false,
    );
    return anyChannelAllowsMultipleConversations || this.multipleConversationsEnabled;
  }

  get supportedChannelIntegrationIds() {
    return this.supportedIntegrations.map((integration) => integration.channel_integration_id);
  }

  async addFinToChannel(channelId: string, brandId: string | number) {
    let response = await post(
      `/ember/standalone/zendesk_configurations/${this.id}/add_fin_to_sunshine_channel`,
      {
        channel_integration_id: channelId,
        brand_id: brandId,
      },
    );

    this.supportedIntegrations.pushObject(response);
  }

  async removeFinFromChannel(channelId: string) {
    await post(
      `/ember/standalone/zendesk_configurations/${this.id}/remove_fin_from_sunshine_channel`,
      {
        channel_integration_id: channelId,
      },
    );

    this.supportedIntegrations = this.supportedIntegrations.filter((integration) => {
      return integration.channel_integration_id !== channelId;
    });
  }

  @action async getUsageCounts(): Promise<{
    involvement_count: number;
    last_active?: string;
  }> {
    return get(`/ember/standalone/zendesk_configurations/${this.id}/sunshine_usage_counts`, {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
    });
  }
}
