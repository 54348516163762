/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';

import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { IntegrationState } from 'embercom/objects/standalone/constants';

export default class ApiConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;

  @attr('string') declare url: string;
  @attr('string') declare accessToken: string;
  @attr('string') declare externalUrl: string;
  @attr('string') declare workflowRulesetId: string;

  get integrationState() {
    if (this.url && this.accessToken && this.externalUrl && this.workflowRulesetId) {
      return IntegrationState.Enabled;
    } else {
      return IntegrationState.NotConfigured;
    }
  }
}
