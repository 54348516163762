/* RESPONSIBLE TEAM: team-channels */
import Model, { attr } from '@ember-data/model';

export default class SenderEmailAddressSummary extends Model {
  @attr('string') declare name: string;
  @attr('string') declare email: string;
  @attr('boolean') declare verified: boolean;
  @attr('string') declare status: string;
  @attr('boolean') declare customBounceValid: boolean;
  @attr('boolean') declare dmarcValid: boolean;
  @attr('number') declare senderEmailAddressSettingsId: number;
}
