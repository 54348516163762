/* RESPONSIBLE TEAM: team-standalone */
import Model, { attr } from '@ember-data/model';
import { ajaxDelete } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

const API_ROOT = '/ember/standalone/salesforce_in_app_messaging_configurations';

export default class SalesforceInAppMessagingConfiguration extends Model {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: $TSFixMe;

  @attr('string') declare organizationId: string | undefined;
  @attr('string') declare deploymentId: string | undefined;
  @attr('string') declare hostname: string | undefined;

  get isPendingSetup() {
    return !this.organizationId || !this.deploymentId || !this.hostname;
  }

  get isConnected() {
    return !this.isPendingSetup;
  }

  async revoke() {
    await ajaxDelete(`${API_ROOT}/${this.id}`);
    this.organizationId = undefined;
    this.deploymentId = undefined;
    this.hostname = undefined;
  }
}
